.slide-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 800px;
}
.slide-container p {
  position: relative;
  /* margin-left: 30px; */
}

.slide-container span {
  position: relative;
  /* margin-left: 30px; */
}

@media (max-width: 450px) {
  .slide-container p {
    margin-left: 24px;
  }

  .slide-container span {
    margin-left: 24px;
  }
}

/* loading image instead of loading spinner */
.galaxisLoading {
  position: absolute;
  top: 40px;
  z-index: -10;
  right: 80px;
  height: 600px;
}

@media (max-width: 1440px) {
  .galaxisLoading {
    right: 40px;
    top: 40px;
    height: 550px;
  }
}

@media (max-width: 1000px) {
  .galaxisLoading {
    height: 420px;
    right: 120px;
    top: 20px;
  }
}

@media (max-width: 750px) {
  .galaxisLoading {
    height: 360px;
    right: 40px;
    top: -20px;
  }
}

@media (max-width: 660px) {
  .galaxisLoading {
    top: 10px;
    right: 40px;
  }
}

@media (max-width: 600px) {
  .galaxisLoading {
    top: 40px;
    right: 20px;
  }
}

@media (max-width: 550px) {
  .galaxisLoading {
    right: 0px;
    border-radius: 8px;
  }
}

@media (max-width: 500px) {
  .galaxisLoading {
    height: 320px;
  }
}

/* @media (max-width: 450px) {
  .galaxisLoading {
    height: 252px;
    right: 20px;
    top: 80px;
  }
} */

@media (max-width: 400px) {
  .galaxisLoading {
    height: 252px;
    right: 20px;
    top: 80px;
  }
}

@media (max-width: 350px) {
  .galaxisLoading {
    height: 220px;
    right: 30px;
    top: 80px;
  }
}

@media (max-width: 320px) {
  .galaxisLoading {
    height: 190px;
    right: 30px;
    top: 80px;
  }
}

/* camelcoding x galaxis image */
.galaxis {
  position: absolute;
  top: 200px;
  z-index: -10;
  right: 80px;
  height: 600px;
}

@media (max-width: 1440px) {
  .galaxis {
    right: 40px;
    top: 200px;
    height: 550px;
  }
}

@media (max-width: 1000px) {
  .galaxis {
    /* height: 420px; */
    right: 40px;
    top: 150px;
    height: 530px;
  }
}

@media (max-width: 750px) {
  .galaxis {
    height: 360px;
    right: 40px;
    top: 140px;
  }
}

@media (max-width: 660px) {
  .galaxis {
    top: 70px;
  }
}

@media (max-width: 600px) {
  .galaxis {
    top: 100px;
    right: 20px;
  }
}

@media (max-width: 550px) {
  .galaxis {
    right: 0px;
    border-radius: 8px;
  }
}

@media (max-width: 500px) {
  .galaxis {
    height: 320px;
  }
}

@media (max-width: 400px) {
  .galaxis {
    height: 252px;
    right: 20px;
    top: 140px;
  }
}

@media (max-width: 350px) {
  .galaxis {
    height: 220px;
    right: 30px;
    top: 140px;
  }
}

@media (max-width: 320px) {
  .galaxis {
    height: 190px;
    right: 30px;
    top: 140px;
  }
}

.card-img {
  position: absolute;
  top: 100px;
  z-index: 0;
  right: 240px;
  transform: rotateZ(15deg);
  height: 500px;
  border-radius: 16px;
}
/* @media (min-width: 2000px){
  .card-img {
  right: 40%;
  top: 150px;
}
} */
@media (max-width: 1440px) {
  .card-img {
    height: 450px;
    right: 200px;
    top: 220px;
  }
}

@media (max-width: 1000px) {
  .card-img {
    height: 420px;
    right: 120px;
    /* top: 65px */
    top: 170px;
  }
}

@media (max-width: 750px) {
  .card-img {
    height: 360px;
    right: 100px;
    top: 70px;
  }
}

@media (max-width: 660px) {
  .card-img {
    height: 330px;
    right: 100px;
    top: 100px;
  }
}

@media (max-width: 600px) {
  .card-img {
    height: 320px;
    right: 65px;
    top: 120px;
  }
}

@media (max-width: 550px) {
  .card-img {
    height: 300px;
    right: 45px;
    top: 140px;
    border-radius: 8px;
  }
}

@media (max-width: 500px) {
  .card-img {
    right: 35px;
  }
}

@media (max-width: 450px) {
  .card-img {
    right: 45px;
    height: 280px;
  }
}

@media (max-width: 400px) {
  .card-img {
    right: 48px;
    height: 230px;
    top: 140px;
  }
}

@media (max-width: 350px) {
  .card-img {
    right: 48px;
    height: 210px;
    top: 150px;
  }
}

.scroll-down-icon {
  transform: rotate(180deg);
  position: absolute;
  width: 64px;
  height: 64px;
  left: 1520px;
  top: 656px;
  cursor: pointer;
  margin: auto;
}

.scroll-to-top {
  cursor: pointer;
  position: absolute;
  top: -18px;
  right: -15px;
}

.animatedBox {
  width: 100%;
  position: relative;
  margin-top: 30px;
  animation: imageFadeOut 4.8s infinite ease-out both;
}

.animatedBox .animatedBox2 {
  position: absolute;
  left: 600px;
  top: -80px;
  transform: rotate(11.99deg);
  z-index: -10;
}

.animatedBox .animatedBox2 img {
  width: 403.04px;
  height: 569.51px;
  border-radius: 8px;
}

@keyframes imageFadeOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.animated-box {
  position: absolute;
  opacity: 0;
}
.animated-box.active-header {
  animation: animation 5s;
}

@keyframes animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*carousel dots styling */

.vertical-dots {
  right: 10px;
  list-style: none;
  display: block;
  position: absolute;
  top: 30%;
  margin-top: -10px;
  text-align: right;
}
.vertical-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 10px 0;
}
.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus {
  outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1;
}
.vertical-dots li button:before {
  border: 2px solid transparent;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 1;
  color: #00d5ff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before {
  opacity: 1;
  /* font-size: 10px; */
  border: 2px solid;
  border-radius: 50%;
  color: #00d5ff;
  padding-top: 1px;
    padding-right: 1px;
  /* padding: 5px; */
  /* top: 0px; */
  /* padding-right: 1px; */
}

@media (max-width: 700px) {
  .vertical-dots li.slick-active button:before {
    /* padding-right: 1px;
    padding-top: 1px; */
    /* right: 10px; */
    /* padding-right: -1px; */
    padding-top: 0px;
    padding-right: 0px;
    text-align: center;
  }
}

.scroll-down-arrow {
  position: absolute;
  right: 0;
}

@media (max-width: 600px) {
  .scroll-to-top {
    width: 40px;
    height: 40px;
    top: -7px;
    right: -7px;
  }
}

.contact {
  display: inline;
  font-weight: bold;
  color: #00d5ff;
  font-family: "segoe-bold";
}

.nft {
  /* opacity: 0.5; */
  border-radius: 10px;
  max-width: 80px;
  max-height: 113px;
  /* filter: grayscale(90%) */
}

.middle {
  background: rgba(255, 255, 255, 0.07);
}

.middle img {
  filter: grayscale(0%);
  opacity: 1;
}

.middleLight {
  background: rgba(0, 213, 255, 0.07);
}

.middleLight img {
  filter: grayscale(0%);
  opacity: 1;
}

.cardStyle {
  opacity: 0.5;
  border-radius: 10px;
  max-width: 80px;
  filter: grayscale(0%);
  opacity: 1;
}

@media (max-width: 1150px) {
  .description {
    max-width: 800px;
  }
}

.row:first-child {
  border-top: 2px solid #00D5FF;
}

.row:hover {
  background: rgba(255, 255, 255, 0.07)
}

@media (max-width: 400px) {
  .row:hover {
    background: none;
  }
}


.light .description {
  color: black
}