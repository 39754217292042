body {
  margin: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

iframe {
  pointer-events: none;
}

.dark {
  background: radial-gradient(
      50% 50% at 50% 100%,
      rgba(0, 213, 255, 0.16) 0%,
      rgba(0, 213, 255, 0) 100%
    ),
    radial-gradient(
      83.23% 25.49% at 0% 38.62%,
      rgba(255, 230, 0, 0.2) 0%,
      rgba(255, 230, 0, 0.04) 100%
    ),
    radial-gradient(
      50% 50% at 100% 0%,
      rgba(0, 213, 255, 0.2) 0%,
      rgba(0, 213, 255, 0) 100%
    ),
    #00001e;
}

.light {
  background: radial-gradient(
      50% 50% at 50% 100%,
      rgba(0, 213, 255, 0.04) 0%,
      rgba(0, 213, 255, 0) 100%
    ),
    radial-gradient(
        100% 30.63% at 0% 38.62%,
        rgba(255, 230, 0, 0.05) 0%,
        rgba(255, 230, 0, 0.01) 100%
      ),
    radial-gradient(
        50% 50% at 100% 0%,
        rgba(0, 213, 255, 0.05) 0%,
        rgba(0, 213, 255, 0) 100%
      ),
    #ffffff;
}

.light span,
.light p {
  /* itt !important volt a black utan */
  color: black ;
}

.light span {
  -webkit-text-stroke: 1px #000000;
  color: transparent;
}

.light .row:hover {
  background-color: rgba(0, 213, 255, 0.07);
}

.light .description {
  color: black
}


@font-face {
  font-family: "segoe";
  src: local("segoe"), url(./assets/fonts/Segoe.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe-bold";
  src: local("segoe-bold"),
    url(./assets/fonts/Segoe-Bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe-ui";
  src: local("segoe-ui"),
    url(./assets/fonts/Segoe-UI.ttf) format("truetype");
  font-display: swap;
}
