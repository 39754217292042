@keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .iframeNeeded {
    pointer-events: all;
  }

  
.light .description {
  color: black
}